@font-face {
  font-family: "HKGrotesk-Black";
  src: url("../assets/fonts/HKGrotesk-Black.otf") format("opentype");
}

@font-face {
  font-family: "HKGrotesk-Regular";
  src: url("../assets/fonts/HKGrotesk-Regular.otf") format("opentype");
}

@font-face {
  font-family: "HKGrotesk-RegularLegacy";
  src: url("../assets/fonts/HKGrotesk-RegularLegacy.otf") format("opentype");
}

@font-face {
  font-family: "HKGrotesk-Medium";
  src: url("../assets/fonts/HKGrotesk-Medium.otf") format("opentype");
}

@font-face {
  font-family: "HKGrotesk-Bold";
  src: url("../assets/fonts/HKGrotesk-Bold.otf") format("opentype");
}

@font-face {
  font-family: "HKGrotesk-BoldItalic";
  src: url("../assets/fonts/HKGrotesk-BoldItalic.otf") format("opentype");
}

@font-face {
  font-family: "HKGrotesk-BoldLegacy";
  src: url("../assets/fonts/HKGrotesk-BoldLegacy.otf") format("opentype");
}

@font-face {
  font-family: "HKGrotesk-BoldLegacyItalic";
  src: url("../assets/fonts/HKGrotesk-BoldLegacyItalic.otf") format("opentype");
}

@font-face {
  font-family: "HKGrotesk-ExtraBold";
  src: url("../assets/fonts/HKGrotesk-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: "HKGrotesk-LegacyItalic";
  src: url("../assets/fonts/HKGrotesk-LegacyItalic.otf") format("opentype");
}

@font-face {
  font-family: "HKGrotesk-Light";
  src: url("../assets/fonts/HKGrotesk-Light.otf") format("opentype");
}

@font-face {
  font-family: "HKGrotesk-LightItalic";
  src: url("../assets/fonts/HKGrotesk-LightItalic.otf") format("opentype");
}

@font-face {
  font-family: "HKGrotesk-LightLegacy";
  src: url("../assets/fonts/HKGrotesk-LightLegacy.otf") format("opentype");
}

@font-face {
  font-family: "HKGrotesk-RegularLegacy";
  src: url("../assets/fonts/HKGrotesk-RegularLegacy.otf") format("opentype");
}
